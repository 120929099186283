import React, { Component } from 'react';

export default class Body
 extends Component {
    
    constructor(props) {
        super(props);
        //this.renderHeader = this.renderHeader.bind(this);
        //this.renderFooter  = this.renderFooter.bind(this);
        this.renderPage = this.renderPage.bind(this);
    }

    render() {
        return (
            <div class="page">
                { this.renderPage() }
            </div>
        );
    }



}