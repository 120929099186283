import React from 'react';
import MainPage from './views/pages/main';

import './App.css';

function App() {
  return (
    <MainPage />
  );
}

export default App;
