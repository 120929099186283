import React from 'react';
import Page from '../components/body';

export default class MainPage extends Page {

    constructor(props) {
        super(props);
    }

    renderPage() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src="/assets/images/twelve-orb-250.png" className="App-logo" alt="logo" />
                    <h3>Site Under Construction</h3>
                </header>
            </div>
        );
    }

}